<template>
  <section class="vizualizar-detalle">
    <Sidebar
      v-model:visible="visible"
      :baseZIndex="1000"
      :position="sidebarPosition"
      class="dark:bg-dark-1 bg-gray-200"
      :class="sidebarClass"
      :style="sidebarStyle"
    >
      <h2 class="font-bold text-gray-700 text-xl pb-4">Detalles de los Productos Financieros</h2>

      <div class="flex w-full justify-between items-center bg-white mb-4 shadow top-0 z-50 sticky">
        <div class="flex flex-col p-4">
          <span class="text-gray-600 font-bold" style="font-size: 0.6rem;">Banco</span>
          <span class="text-xs md:text-base font-bold capitalize">{{ nameBank }}</span>
        </div>
        <div class="flex flex-col p-4">
          <span class="text-gray-600 font-bold" style="font-size: 0.6rem;">Línea de Crédito</span>
          <span class="text-xs md:text-base font-bold">{{ creditLineName }}</span>
        </div>
        <div class="flex flex-col p-4 flex-shrink-0">
          <span class="text-gray-600 font-bold" style="font-size: 0.6rem;"># Créditos</span>
          <span class="text-xs md:text-base font-bold">{{ totalCreditosVal }}</span>
        </div>
      </div>

      <div v-for="(detail, index) in detailsProducts" :key="index">
        <product-card :infoProducto="detail" :testProp="detail.disbursment"/>
      </div>

    </Sidebar>
  </section>
</template>

<script>
import { defineAsyncComponent, ref } from 'vue'
import { useToast } from 'primevue/usetoast'
import listDetailsProducts from '../../../../../services/reportCreditLinesBankProduct'

export default {
  name: 'sidebarDetalle',
  components: {
    productCard: defineAsyncComponent(() => import('../productCard'))
  },
  props: {
    nameBank: {
      type: String,
      default: ''
    },
    creditLineName: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const toast = useToast()
    const visible = ref(false)
    const detailsProducts = ref({})
    const totalSaldoNew = ref(null)
    const totalCreditosVal = ref(null)
    const sidebarPosition = ref(window.innerWidth > 1024 ? 'right' : 'full')
    const sidebarClass = ref(window.innerWidth > 1024 ? 'p-sidebar-md' : '')
    const sidebarStyle = ref(window.innerWidth > 1024 ? 'width: 50vw' : '')

    const toggle = async (bankId, creditLineId, totalSaldo, totalCreditos) => {
      await fetchDetailsProducts({ bankId, creditLineId })
      if (detailsProducts.value.length <= 0) {
        toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'No hay informacion para la línea de crédito', life: 3000 })
      } else {
        totalSaldoNew.value = totalSaldo
        totalCreditosVal.value = totalCreditos
        visible.value = !visible.value
      }
    }

    const fetchDetailsProducts = (filter = {}) => {
      return listDetailsProducts(filter).then(({ status, data }) => {
        detailsProducts.value = data.result
      }).catch(err => {
        console.error(err.message)
      })
    }

    window.addEventListener('resize', evt => {
      sidebarPosition.value = window.innerWidth > 1024 ? 'right' : 'full'
      sidebarClass.value = window.innerWidth > 1024 ? 'p-sidebar-md' : ''
      sidebarStyle.value = window.innerWidth > 1024 ? 'width: 50vw' : ''
    })

    return {
      visible,
      toggle,
      sidebarPosition,
      props,
      detailsProducts,
      sidebarClass,
      sidebarStyle,
      totalSaldoNew,
      totalCreditosVal
    }
  }
}
</script>

<style scoped>

</style>
